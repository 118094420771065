import Axios from "axios";
import router from "../router";
import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class HttpInterceptorsConfig {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
		this.axios.interceptors.response.use(
			response => response,
			error => {
				switch (error.response.status) {
					case 401:
						router.push("/forbidden401");
						break;
					case 403:
						router.push("/forbidden");
						break;
				}

				return Promise.reject(error);
			}
		);
	}
}